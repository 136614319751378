import './sass/style.scss'

import $ from 'jquery';

$(document).ready(function() {
    $('#form-contato').on('submit', function (e) {
        e.preventDefault();
        /* Coletando dados */
        var nome = $('#nomecontato').val();
        var email = $('#emailcontato').val();
        var telefone = $('#telefonecontato').val();
        var mensagem = $('#mensagemcontato').val();
        var cidade = $('#empresacidade').val();
        /* construindo url */
        var urlData = 
        "&nome="     + nome      +
        "&email="    + email     +
        "&telefone=" + telefone  +
        "&cidade="  + cidade   +
        "&mensagem=" + mensagem;    
    
        var url_script_php = "https://vtambiental.com.br/wp-content/themes/beakai/inc/includes/PHPMailer/forms-proambiental/form-contato.php";
        /* Ajax */
        $.ajax({
            type: "POST",
            url: url_script_php, /* endereço do script PHP */
            async: true,
            data: urlData, /* informa Url */
            success: function (data) { /* sucesso */
                var returnedData = JSON.parse(data);
                if (returnedData["success"]) {
                    $('.conteudoRetorno').css("display", "block");
                    $('.conteudoRetorno').html('Mensagem enviada com sucesso, aguarde e entraremos em contato');
                }else{
                    $('.retornoForms').removeClass('form-sucess');
                    $('.retornoForms').addClass('form-erro');
                    $('.conteudoRetorno').html('ERRO: Mensagem não pode ser enviada.' + returnedData["message"]);
                    $('.retornoForms').fadeIn('fast');
                }
            },
            beforeSend: function () { /* antes de enviar */
                $('.conteudoRetorno').html('Enviando ...');
                $('.retornoForms').addClass('form-sucess');
                $('.retornoForms').css("display", "block");
            },
            complete: function () { /* completo */
                $('#form-contato')[0].reset();
                setTimeout(() => {
                //$('.retornoForms').css("display", "none"); 
                }, 5000);

                function gtag_report_conversion(url) {
                    var callback = function () {
                        if (typeof(url) != 'undefined') {
                            //window.location = url;
                        }
                    };
                    gtag('event', 'conversion', {
                        'send_to': 'AW-10802391924/fdOfCIGq-Z0DEPTW_Z4o',
                        'event_callback': callback
                    });
                    return false;
                }

                gtag_report_conversion('https://vtambiental.com.br')
            }
        });
    });
    
    if(sessionStorage.getItem('cookie')==null){
        $("#cookiesModal").css("display", "flex"); 
    }
    
    $("#cookieClose").click(function(){
        $("#cookiesModal").css("display", "none");
        sessionStorage.setItem('cookie', true);
    });
});
